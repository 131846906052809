<template>
  <div class="page_bg" style="background:rgb(13, 81, 130)">
    <div class="invite_bg">
      <div class="bg01" />
      <div class="bg02 flex-c-c-c">
        <van-image width="7.74rem" height="2.44rem" style="margin-top:5rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/invite/2.2.jpg" @click="show = true"
        />
        <van-image width="7.74rem" height="2.44rem" style="margin-top:0.43rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/invite/2.3.jpg" @click="show = true"
        />
      </div>
      <div class="bg03">
        <van-image width="7.74rem" height="2.44rem" style="margin-top:6.5rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/invite/3.2.jpg" @click="show = true"
        />
        <van-image width="7.74rem" height="2.44rem" style="margin-top:0.3rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/invite/3.3.jpg" @click="show = true"
        />
        <van-image width="7.74rem" height="2.44rem" style="margin-top:0.3rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/invite/3.4.jpg" @click="show = true"
        />
        <van-image width="7.74rem" height="2.44rem" style="margin-top:0.3rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/invite/3.5.jpg" @click="show = true"
        />
        <van-image width="7.74rem" height="2.44rem" style="margin-top:0.3rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/invite/3.6.jpg" @click="show = true"
        />
      </div>
      <div class="bg04" @click="show = true" />
      <div class="bg05" />
      <div class="bg06" />
    </div>
    <!-- 弹出mask -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper flex-c-c-c" @click.stop="show = false">
        <van-image width="2.13rem" height="2.13rem" style="margin-left:7rem;margin-top:1rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/linkPage/invite_mask_arrow.png"
        />
        <van-image width="5.39rem" height="5.36rem" style="margin-top:2rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/linkPage/invite_mask_pic.png"
        />
        <div style="font-size: 0.37rem;color: #FFFFFF;">点击右上角，快快分享领好礼吧！</div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import './invite.scss'
import { user_invites, user_inviteCode } from '@/api/user'
export default {
  name: 'Invite',
  data() {
    return {
      list: [],
      show: false,
      inviteCode: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      sessionStorage.setItem('lasterRouter', from.path)
    })
  },
  created() {
    console.log('pagescount', sessionStorage.getItem('lasterRouter'))
    if (sessionStorage.getItem('lasterRouter')) {
      this.getInfo()
      this.getList()
      this.shareDetail()
    } else {
      // 来自于分享进入
      this.$router.replace('/user/inviteReg?code=' + this.$route.query.code)
    }
  },
  methods: {
    getInfo() {
      user_inviteCode().then(res => {
        const query = JSON.parse(JSON.stringify(this.$route.query))
        query.code = res.data.inviteCode
        this.$router.push({ path: this.$route.path, query })
        this.inviteCode = res.data.inviteCode
      })
    },
    getList() {
      user_invites({ pageNum: 1, pageSize: 9999 }).then(res => {
        this.list = res.data.data
        // for (const i in this.list) {
        //   const phone = this.list[i].mobile
        //   this.list[i].mobile = phone.substr(0, 3) + '****' + phone.substr(7)
        // }
      })
    },
    // ---------------------------------- 分享配置 ----------------------------------
    shareDetail() {
      this.$wechat.share({
        title: '兽医首选-邀请有礼',
        img: 'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/uniapp/invite/invite_redpackter.png',
        desc: `优选商城，品类齐全，冰点价格，快来注册吧！`
      })
    }
  }
}
</script>

<style>
/* .invite_bg {
  width: 100%;
  min-height: 15.02rem;
  background: #ffffff;
  background: url("https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/wholesale/invite/invite2_bg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
} */
.invite_my {
  width: 9.9rem;
  height: 7.6rem;
  /* margin-left: 0.25rem; */
  background: url("https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/wholesale/invite/invite3_mes_box.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.invite_my_item {
  width: 8.67rem;
  height: 5.17rem;
  /* background: rgb(22, 78, 158); */
  border-radius: 0.27rem;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 1.4rem;
}
.item_user {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin-left: 0.3rem;
  width: 8rem;
  border-bottom: 1px solid rgba(218, 239, 255, 0.2);
}
.info_mobile,
.item_user_ok {
  font-size: 0.32rem;
  color: #333;
}
</style>
